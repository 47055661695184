import {Button, CircularProgress, Grid, Table} from "@mui/material";
import {Dropdown} from "../../dropdown/Dropdown.jsx";

import "../../../scss/DataSubscription.scss"
import {
    useFetchGeoFamilyIds,
    useFetchMdmBrands,
    useFetchMdmCompanies,
    useFetchSubcategories,
    useFetchSubscriptions,
    useListState
} from "./DataSubscription.hooks.jsx";
import {useState} from "react";
import {ComboBox} from "../../comboBox/ComboBox.jsx";
import {handleDelete, handleSave} from "./DataSubscription.handlers.js";
import {ToastContainer} from "react-toastify";


export const DataSubscription = () => {
    const auth0Token = window.localStorage.getItem('access_token');

    const {companyList, isCompaniesLoading} = useFetchMdmCompanies()
    const [selectedCompany, setSelectedCompany] = useState()
    const {brandList, isBrandListLoading} = useFetchMdmBrands()
    const [selectedBrands, setSelectedBrands] = useState([])
    const {geoFamilyIds, isGeoFamilyIdsLoading} = useFetchGeoFamilyIds()
    const [selectedGeoFamilyIds, setSelectedGeoFamilyIds] = useState([])
    const {subcategories, isSubcategoriesLoading} = useFetchSubcategories()
    const [selectedSubcategory, setSelectedSubcategory] = useState()
    const [isSaving, setIsSaving] = useState(false)
    const {activeSubscriptions, fetchActiveSubscriptions, isActiveSubscriptionsLoading} = useFetchSubscriptions(selectedCompany?.value)
    const [subscriptions, addToSubscriptions, resetSubscriptions, removeSubscription] = useListState([])

    return (
        <div id="data-subscription"
             style={{
                 display: "flex",
                 alignItems: "center",
                 justifyContent: 'center',
                 flexDirection: "column",
                 margin: "30px",
                 padding: "10px",
                 border: "solid"
             }}>
            <ToastContainer/>
            <Grid container spacing={2}>
                <Grid item md={12} style={{display: "flex", alignItems: "center", gap: "20px"}}>
                    <span> Data Subscription </span>
                    <Dropdown
                        label='Application'
                        data={[{
                            value: 'Application Filter (Ignite Plus)',
                            displayName: 'Application Filter (Ignite Plus)'
                        }]}
                        currentValue='Application Filter (Ignite Plus)'
                        onChange={() => {
                        }}
                    />
                </Grid>

                <Grid item md={6}>
                    {isCompaniesLoading ?
                        <CircularProgress/> :
                        <ComboBox
                            label="Company / Organization (SF ID)*"
                            value={selectedCompany}
                            isMultiple={false}
                            disableCloseOnSelect={false}
                            data={companyList}
                            getOptionKeyImplementation={(option) => option.value}
                            onChange={(event, value) => {
                                setSelectedCompany(value)
                            }}
                        />
                    }
                </Grid>
                <Grid item md={6}>
                    {isBrandListLoading ?
                        <CircularProgress/> :
                        <ComboBox
                            label="Brand*"
                            value={selectedBrands}
                            isMultiple={true}
                            disableCloseOnSelect={false}
                            data={brandList}
                            getOptionKeyImplementation={(option) => option.value}
                            onChange={(event, value) => {
                                setSelectedBrands(value)
                            }}
                        />
                    }
                </Grid>
                <Grid item md={6}>
                    {isGeoFamilyIdsLoading ?
                        <CircularProgress/> :
                        <ComboBox
                            label="Geo Family ID*"
                            value={selectedGeoFamilyIds}
                            isMultiple={true}
                            disableCloseOnSelect={false}
                            data={geoFamilyIds}
                            getOptionKeyImplementation={(option) => option.value}
                            onChange={(event, value) => {
                                setSelectedGeoFamilyIds(value)
                            }}
                        />
                    }
                </Grid>
                <Grid item md={6}>
                    {isSubcategoriesLoading ?
                        <CircularProgress/> :
                        <ComboBox
                            label="Subcategories*"
                            value={selectedSubcategory}
                            isMultiple={false}
                            disableCloseOnSelect={false}
                            data={subcategories}
                            getOptionKeyImplementation={(option) => option.value}
                            onChange={(event, value) => {
                                setSelectedSubcategory(value)
                            }}
                        />
                    }
                </Grid>
                <Grid item md={6}>
                    <Dropdown
                        styles={"dropdown"}
                        label='Product Level'
                        name='Product Level'
                        data={[{value: 'BRAND', displayName: 'BRAND'}]}
                        currentValue='BRAND'
                        disabled={true}
                    />
                </Grid>
                <Grid item md={6} style={{display: "flex", alignItems: "center", gap: "20px"}}>
                    <Button
                        className={"save-button"}
                        variant="contained"
                        onClick={() => {
                            addToSubscriptions(selectedGeoFamilyIds.map((geoFamilyId) => ({
                                company: selectedCompany,
                                geoFamilyId: geoFamilyId,
                                subcategory: selectedSubcategory,
                                productLevel: 'BRAND',
                                enabled: true,
                                brands: selectedBrands
                            })))
                        }}
                    >
                        INSERT
                    </Button>

                    <Button
                        className={"save-button"}
                        variant="outlined"
                        onClick={resetSubscriptions}
                    >
                        CLEAR
                    </Button>
                </Grid>
            </Grid>
            <hr/>
            <div style={{width: '100%'}}>
                <p>Preview/Edit</p>
                <p>Company/Organization: {selectedCompany?.displayName}</p>
                <h5>Subscriptions:</h5>
                <Table>
                    <thead>
                    <tr>
                        <th>S. No</th>
                        <th>Geo Family ID</th>
                        <th>Subcategory</th>
                        <th>Product Level</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {subscriptions.length > 0 && <h6>Pending Additions</h6>}
                    {subscriptions.map((subscription, index) => (
                        <tr key={index}>
                            <td></td>
                            <td>{subscription.geoFamilyId.displayName}</td>
                            <td>{subscription.subcategory.displayName}</td>
                            <td>{subscription.productLevel}</td>
                            <td onClick={() => removeSubscription(index)}
                                style={{cursor: 'pointer', textDecoration: 'underline'}}> DELETE
                            </td>
                        </tr>
                    ))}
                    {activeSubscriptions?.length > 0 && <h6>Current Subscriptions</h6>}
                    {isActiveSubscriptionsLoading && <CircularProgress/>}
                    {!isActiveSubscriptionsLoading && activeSubscriptions?.map((subscription) => (
                        <tr key={`active-subcription-${subscription.id}`}>
                            <td>{subscription.id}</td>
                            <td>{subscription.sec_mkt_key}</td>
                            <td>{subcategories.find((subcategory) => subcategory.value === subscription.sec_prd_key.split('|')[1])?.displayName || subscription.sec_prd_key.split('|')[1]}</td>
                            <td>{subscription.prd_lvl}</td>
                            <td onClick={() => handleDelete(subscription.id, auth0Token).then(() => fetchActiveSubscriptions())}
                                style={{cursor: 'pointer', textDecoration: 'underline'}}>
                                DELETE
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>


                <div style={{display: "flex", justifyContent: "center"}}>
                    <Button
                        className={"save-button"}
                        variant="outlined"
                        disabled={subscriptions.length === 0 || isSaving}
                        onClick={() => {
                            setIsSaving(true)
                            selectedCompany && handleSave({
                                companyId: selectedCompany.value,
                                subscriptions
                            }, auth0Token, setIsSaving).then(() => {
                                fetchActiveSubscriptions()
                                resetSubscriptions()
                            })
                        }}
                    >
                        SAVE
                    </Button>
                </div>
            </div>

        </div>
    )

}
