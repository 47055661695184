import {useEffect, useState} from "react";
import axios from "axios";
import {DAG_IDS} from "./Dags.constants.js";

/* dag_runs response
 {"conf": {},
  "dag_id": "datascience-workspace-vendorscorecard.vendorscorecard-pipeline",
  "dag_run_id": "scheduled__2024-03-13T14:00:00+00:00",
  "data_interval_end": "2024-03-14T14:00:00+00:00",
  "data_interval_start": "2024-03-13T14:00:00+00:00",
  "end_date": "2024-03-14T14:00:09.724135+00:00",
  "execution_date": "2024-03-13T14:00:00+00:00",
  "external_trigger": false,
  "last_scheduling_decision": "2024-03-14T14:00:09.712311+00:00",
  "logical_date": "2024-03-13T14:00:00+00:00",
  "run_type": "scheduled",
  "start_date": "2024-03-14T14:00:00.561504+00:00",
  "state": "success"}
 */
const LIST_DAGS_API = (dag_id, webserver) => `${process.env.REACT_APP_RIS_API_URL}/legacy-scorecards/dags/list-dag-runs?dag_id=${dag_id}&webserver=${webserver}`;
const DAG_INFO_API = (dag_id, webserver) => `${process.env.REACT_APP_RIS_API_URL}/legacy-scorecards/dags/dag?dag_id=${dag_id}&webserver=${webserver}`;

export function useFetchDagRuns(auth0AccessToken, errorHandlerFn = console.log) {
    const [dagRuns, setDagRuns] = useState([[], [], [], [], [], []]);
    useEffect(() => {
        const abortController = new AbortController()

        const fetchDagRuns = async () => {
            return await Promise.allSettled(
                DAG_IDS.map(({dagId, webserver}) => axios.get(LIST_DAGS_API(dagId, webserver), {
                    headers: {
                        'authorization': `Bearer ${auth0AccessToken}`
                    },
                    signal: abortController.signal
                }))).then((results) => results.map((res) => res.value.data))
        }

        fetchDagRuns().then(res => {
            setDagRuns(res)
        }).catch((e) => errorHandlerFn(e))
        return () => {
            abortController.abort()
        }

    }, [auth0AccessToken]);

    return dagRuns

}

export function useFetchDagInfo(auth0AccessToken, errorHandlerFn = console.log) {
    const [dagInfo, setDagInfo] = useState([]);
    useEffect(() => {
        const abortController = new AbortController()

        const fetchDagInfo = async () => {
            return await Promise.allSettled(
                DAG_IDS.map(({dagId, webserver}) => axios.get(DAG_INFO_API(dagId, webserver), {
                    headers: {
                        'authorization': `Bearer ${auth0AccessToken}`
                    },
                    signal: abortController.signal
                }))).then((results) => results.map((res) => res.value.data))
        }

        fetchDagInfo().then(res => {
            setDagInfo(res)
        }).catch((e) => errorHandlerFn(e))
        return () => {
            abortController.abort()
        }

    }, [auth0AccessToken]);

    function updateDagInfo(index) {
        return (data) => {
            return setDagInfo((p) => {
                const newP = [...p]
                newP[index] = data
                return newP
            })
        }
    }

    return [dagInfo, updateDagInfo]
}